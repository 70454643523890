<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="门诊简介"
        />
        <div style="margin-left: 15px;margin-right: 15px">
            <br>
            <h3 style="text-align: left">肩痛联合门诊</h3>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg :src="require('../../img/jianjie/jianjie1.png')"> </vimg>
            <br>

            <p  style="text-indent:2em">肩痛联合门诊时间：每周四上午8：30</p>
            <p  style="text-indent:2em">肩痛联合门诊地点：门诊大楼8层C区7诊室和 8诊室</p>
            <br>
            <h3  style="text-indent:2em">挂号方法：</h3>
            <p  style="text-indent:2em">1)	预约方法：患者可用解放军总医院的APP、各网络挂号平台提前预约骨科张强副主任医师或疼痛科杨静副主任医师每周四上午的专家号，如需两位专家同时会诊，可每周四当日上午于门诊8楼C区810挂取肩痛联合门诊号。     </p>
            <p  style="text-indent:2em">2)	超声科王月香主任专家诊时间及地点：每周二、四下午门诊6层602区第1或10诊室，请提前挂号或携肩痛联合门诊检查单就诊进行肩部超声检查。</p>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg  round width="40%"
                  :src="require('../../img/jianjie/jianjie2.png')"

            />
            <h3  style="text-indent:2em">张强</h3>
            <p  style="text-indent:2em">解放军总医院第一医学中心（原301医院）骨科运动医学科主任，国家公派留学归国人员，取得英国Honorary Clinical Research Fellow身份。</p>
            <p  style="text-indent:2em">从事微创关节外科工作，涵盖从关节镜下修复到全关节置换，尤其擅长肩、肘关节镜及反式肩关节置换（解放军总医院首例反肩置换术者）等技术。</p>
            <p  style="text-indent:2em">国际矫形与创伤外科学会（SICOT）中国部肩肘委员会常务委员，中国骨科菁英会运动医学分会执行委员，中国医师协会骨科分会肩肘委员会委员，中国医师协会运动医学分会肩肘委员会委员，北京市医学会运动医学分会委员，《中华肩肘外科杂志》编委。</p>
            <p  style="text-indent:2em">承担多项国家自然科学基金、北京市自然科学基金并参与国家863、军队重点课题，北京市科技新星，以第一作者及通讯作者发表SCI 论文22篇，是国际上肩关节喙突撞击分型（INT ORTHOP）；肘关节镜结合OK技术(JSES)；双功能束前交叉韧带重建术式（KSSTA）的提出者，是国际上较早开展全镜下交叉韧带直接缝合修复(2014-今，CJSM；CORR) 的学者之一，以及MPFL+MPTL重建膑股关节不稳（KSSTA）和髁间脊骨折TAK技术（MSM）发明者，并研发了具有自主知识产权的多款国产器械造福众多患者。</p>
            <p  style="text-indent:2em">解放军总医院第四医学中心第一届党代表，解放军总医院派驻宣汉县扶贫奔康“好医生”，事迹入选中央电视台《奋斗强军故事会》展播。</p>
            <p  style="text-indent:2em">出诊时间： 1、周四上午门诊楼8层C区8号会诊室；2、周四下午门诊楼五层风湿骨科诊区508诊室</p>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg round width="40%" :src="require('../../img/jianjie/jianjie3.png')"> </vimg>
            <h3  style="text-indent:2em">王月香</h3>
            <p  style="text-indent:2em">中国人民解放军总医院第一医学中心（原301医院）超声诊断科副主任、主任医师。</p>
            <p  style="text-indent:2em">长期从事四肢关节、肌腱、韧带、周围神经等软组织超声检查与介入治疗工作，在四肢软组织疼痛的超声诊断上具有丰富的经验。主编6部肌骨超声专著，主译4部肌骨超声专著。为2015年和2020年中国医师协会超声医师分会主办的《中国肌骨超声指南》主要编著者之一。目前任中国医师协会肌骨超声专业委员会副主任委员、中国老年医学会超声医学分会副会长。</p>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg round width="40%" :src="require('../../img/jianjie/jianjie4.png')"> </vimg>

            <h3  style="text-indent:2em">杨静</h3>
            <p  style="text-indent:2em">解放军总医院第一医学中心（原301医院）疼痛科副主任医师。</p>
            <p  style="text-indent:2em">2001年、2004年和2009年分别于第四军医大学和解放军总医院获取本科、硕士及博士学历。2013年于美国克利夫兰医学中心疼痛科学习并获得博士后学位。现任中国医师协会神经调控专业委员会疼痛学组委员，北京医学会疼痛学分会青年委员。专注于颈胸腰臀痛等骨科脊柱源性疼痛和肩等关节源性疼痛，长期参与解放军总医院第一医学中心的肩痛联合门诊，擅长超声/CT/C型臂引导下的疼痛微创介入治疗，例如超声引导下的肩关节及周围组织注射治疗，善于结合个体化运动康复训练治疗骨科疾病相关的慢性疼痛。</p>
            <p  style="text-indent:2em">出诊时间：周四上午门诊楼8层C区7号会诊室；周一下午门诊楼五层风湿骨科诊区508诊室</p>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg round width="40%" :src="require('../../img/jianjie/jianjie5.png')"> </vimg>

            <h3  style="text-indent:2em">李圣节</h3>
            <p  style="text-indent:2em">中国人民解放军总医院第一医学中心（原301医院）康复医学科 主管康复师</p>
            <p  style="text-indent:2em">从事肌肉骨骼系统疾患的康复治疗和老年康复保健工作，擅长肩痛相关疾病的康复治疗，擅长脊柱相关疾病的康复治疗。获得首届北京市康复治疗师竞赛“优秀康复治疗师”称号，中国康复医学会“优秀青年康复治疗师”称号，解放军总医院科技进步一等奖。</p>
            <p  style="text-indent:2em">出诊时间：周四上午门诊楼8层C区8号会诊室</p>
            <p  style="text-indent:2em"></p>
            <br>
            <vimg round width="40%" :src="require('../../img/jianjie/jianjie6.png')"> </vimg>
            <h3  style="text-indent:2em">许霖 </h3>
            <p  style="text-indent:2em">中国人民解放军总医院第一医学中心（原301医院）放射诊断科副主任医师</p>
            <p  style="text-indent:2em">以第一作者或通讯作者发表专业论文7篇，其中SCI收录2篇，获解放军总医院临床扶持基金项目一项，参译著作1本，副主编著作1本。长期从事四肢关节、肌腱、韧带损伤影像判读，在运动医学影像学诊断上具有丰富经验。目前任北京市医学会放射学分会骨关节学组委员。</p>
            <p  style="text-indent:2em">出诊时间：周四上午门诊楼8层C区8号会诊室</p>

        </div>

        <br>
        <br>


        <RLFooter/>
    </div>
</template>

<script>

    import {Image as vimg} from 'vant';

    export default {
        name: 'Jianjie',
        components: {
            vimg
        },
        data(){
            return {
                followcrfs:[],
                nodata:false
            };
        },
        computed:{

        },
        methods:{

        }
        ,
        created() {


        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
